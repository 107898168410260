import React, {useState} from 'react'
import axios from 'axios'
import { navigate } from 'gatsby-link'

import Layout from '../../components/Layout'

import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";

import RegisterHero from "../../img/register/registerHero.png";

const useStyles = makeStyles(theme => ({
    fullContainer: {
        backgroundColor: "#f1f1f1",
        minHeight: "90vh",
    },
    narrowContainer: {
        minHeight: "90vh",
        paddingTop: "1rem",
        paddingBottom: "1rem",        
    },
    heroRight: {
        backgroundOrigin: "content-box",
        background: `url(${RegisterHero})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "center right",
    }
    
}));


export const RegisterPageTemplate = () => {

    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [botField, setBotField] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(email && botField === ''){
            try{
                let response = await axios({
                    method: 'post',
                    url: '/.netlify/functions/mailing-list-registration',
                    responseType: 'json',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        "email": email,
                    }
                })
            }
            catch(error){
                console.log("error: ", error);
            }
            navigate("/register/thanks");
        }
    }
    
    return (
        <Container maxWidth={false} className={classes.fullContainer}>
            <Container>
                <Grid container className={classes.narrowContainer}>
                    <Grid item container xs={12} md={6} direction="column" alignItems="center" justify="center">
                        <Typography variant="h2" component="p" align='left' style={{color: "#262626", paddingBottom: "1rem"}}>
                            Dance with Clarity        
                        </Typography>
                        <Typography variant="body1" component="p" align='left' style={{color: "#262626", paddingBottom: "1rem"}}>
                            To get updates from DanceClarity and to be part of our free early access launch in 2021, register below. Join our mailing list and have a hand in shaping the future of dance education.         
                        </Typography>
                        <div style={{width: "100%"}}>
                            <form name="contact"
                                onSubmit={handleSubmit}
                                autoComplete="off"
                                style={{ padding: "0.5rem", width: "100%", display: "flex", direction: "row"}}>
                                <input type="hidden" name="form-name" value="contact" />
                                <div hidden><label htmlFor="bot-field">field<input id="bot-field" name="bot-field" value={botField} onChange={(e) => setBotField(e.target.value)}/></label></div>
                                <TextField id="email" label="Enter your email address" variant="outlined" type="email" required value={email} onChange={(e) => {setEmail(e.target.value)}} style={{flexGrow: "3"}}/>
                                <Button variant="contained" color="secondary" type="submit" style={{marginLeft: "5px", flexGrow: "1"}}>Submit</Button>
                            </form>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.heroRight}>

                    </Grid>
                    
                </Grid>
            </Container>
        </Container>
    )
}

const RegisterPage = () => {
    return (
        <Layout>
            <RegisterPageTemplate/>
        </Layout>
    )
}

export default RegisterPage;